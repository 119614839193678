import React, { Component } from "react";
import PropTypes from "prop-types";

export class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area d-print-none">
          <div className="container">
            <div className="row mb-20">
              <div className="col-lg-6 col-md-6">
                <p>Copyright @ {new Date().getFullYear()} {this.props.companyName}. Todos os direitos reservados.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h6>Ouvidoria</h6>
                <p className="mb-0"><b>Telefone:</b> 0800 703 1989 ou (21) 2507-3112 ramais 22 ou 23</p>
                <p className="mb-10"><b>E-mail:</b> ouvidoria@sinapp.org.br</p>
              </div>
              <div className="col-lg-6">
                <h6>Atendimento a Deficientes Auditivos ou de Fala</h6>
                <p className="mb-0"><b>Telefone:</b> 0800 200 0819</p>
                <p className="mb-10"><b>E-mail:</b> sac.especial.auditivo.fala@sinapp.org.br</p>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
Footer.PropsTypes = {
  companyName: PropTypes.string,
};
Footer.defaultProps = {
  companyName: "",
};
export default Footer;
